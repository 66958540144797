import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "./feature-section"

import InPortal from "./portal-esgc"
import Modal2 from "./modal-content-for-portal"
import {isMobile} from 'react-device-detect';

import { 
  Container, 
  Flex,
  FlexList,
  Box,
  Kicker, 
  Heading,
  SubheadSmaller,
  Text,
} from "./ui"
import { 
  hideOnSmallMedia,
  paddingBoxRightLeft,
  boxEsgcCircleMargin,
  imgCirclesEsg,
  boxGradientGreen,
  boxRowOneSmallDevices,
  boxEsgcImg,
  boxEsgcImgBg,
  boxEsgcImgBgNew,
  boxHeader,
  imgEsgcCircleInBox,
  feartureListSecondRowShift,
  featureListItem,
  featureListBottomRow,
  divClickable,
} from "../tailor.module.css"


// keep padding 4 since this is used by media settings too
export default function FeatureList(props) {

  const [isOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflowY = 'visible';
  }
  
  //console.log("....................02 isOpen:",isOpen)

  return (   
    <Container width="fullbleed" id="wasist" >      
      <Box id="vorteileBox1" radius="small" shadow="large" textcolor="background"
        className={ `${boxGradientGreen} ${paddingBoxRightLeft} ${boxEsgcCircleMargin}` }
        >
        <Flex id="flex1" variant="column" style={{ alignItems: "flex-start", padding: "0" }} >

            <FlexList id="flex2" gap={4} responsive
              className={boxRowOneSmallDevices}
              > 
<li>
              <Box id="vorteileBox2" width="full" 
                className={boxEsgcImgBgNew}>
                <Box id="vorteileBox3" 
                  className={`${boxEsgcImg} ${divClickable}`}
                  onClick={() => isOpen ? closeModal() : setIsOpen(true)} >
                    {isOpen && !isMobile && 
                      <InPortal id="portalcontainer">
                        <Modal2 isCircle={true} state="2" />
                      </InPortal>
                    }
                    <StaticImage src="../images/esgc_kreis_02_w2500.png" alt="esg controlling circle 2" 
                      width={550} placeholder="blurred" 
                        className={imgEsgcCircleInBox} />
                </Box>
              </Box>
</li>
<li>
              <Box id="vorteileBox4" center paddingY={5} padding={0} 
                className={boxHeader}
                >
                <Heading>
                  {props.kicker && <Kicker>{props.kicker}</Kicker>}
                  {props.heading}
                </Heading>           
              </Box> 
</li>
            </FlexList>

            <FlexList id="flex3" gap={4} variant="start" responsive className={feartureListSecondRowShift}>   {/* appears as row */}
                {props.content.map((details, i) => (
                  <li key={details.id} className={featureListItem}>
                    <FeatureSection {...details} />
                  </li>
                ))}
            </FlexList>

            <Box id="vorteileBox5"
              className={`${featureListBottomRow}`}>
                {props.text && 
                  <Text as="p" variant="lead" >{props.text}</Text>}
            </Box>

        </Flex>


      </Box>
    </Container>
     )
}

