/*
https://www.joshwcomeau.com/snippets/react-components/in-portal/
*/
import React from 'react';
import ReactDOM from 'react-dom';


export default function InPortal({ id, children }) {

  const [hasMounted, setHasMounted] = React.useState(false);
 
  //console.log("....................portal-esgc: hasMounted #1 :",hasMounted)
  //console.log("....................portal-esgc: children :",children)

  
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  
  // console.log("     InPortal: hasMounted:",hasMounted)
  // console.log("     InPortal: children:",children)
  // console.log("     InPortal: children:",children.props.isOpen)


  if (!hasMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    children,
    document.querySelector(`#${id}`)
  );
}
