import * as React from "react"

import {
  Section,
  Container, 
  Box, 
  NavLink,
  Kicker, 
  SubheadSmall, 
  Flex, 
  FlexList, 
  Text, 
} from "./ui"
import { navlink } from "./ui.css"
import { 
  fontSmallMedia, 
  container00Position,
  conversionListWrapper, 
  conversionListTop, 
  conversionListBottom, 
  conversionListCenter, 
  conversionListItem,
  conversionItems,
  fontLeadSmaller,
  postItemHeading,
  listArrowBullets,
  listArrowBulletsUl,
  navBarThrid,
  navBarThridLi,
  navBarThridItem,
  navBarThridItemNew,
} from "../tailor.module.css"



function ConversionItem(props, index) {
  return (

      <div id="convItemBlocks" className={conversionItems}>

          <div className={fontSmallMedia}>
            <Text as="h3" variant="stat" >
              {props.heading}
            </Text>
          </div>

          {props.subheading && 
            <Text as="h4" variant="medium" className={postItemHeading} > 
            {props.subheading}
          </Text>}
          <Flex id="postChildrenTest" variant="spaceBetween" gap={1}>
            {props.children.map((message, index) => (
                <Text key={index} as="p" variant="body" >{message.textContent}</Text>
            ))}
          </Flex>

      </div>

  )
}

// R E F A C T O R : conversionListWrapper fixing height sucks
export default function ConversionListTest(props) {


  const navDataThird = {
    "layout": {
            "id": "nav3gkjf",
            "navItems": [
              {
                "id": "nav31vbd",
                "href": "/#vorteile",
                "text": "Vorteile des ESG-Controllings"
              },
                  {
                    "id": "nav32bbm",
                    "href": "/#einsatz",
                    "text": "Wo setzen wir ESG-Controlling ein?"
                  },
              {
                "id": "nav33hji",
                "href": "/#wasist",
                "text": "Was ist ESG-Controlling?"
              },
                  {
                    "id": "nav34vcj",
                    "href": "/#aufwand",
                    "text": "Aufwand bei der Umsetzung des ESG-Controllings"
                  },
              {
                "id": "nav35mqe",
                "href": "/#scorecard",
                "text": "Die ESG-Controlling Scorecard"
              },
                  {
                    "id": "nav36dvz",
                    "href": "/#sdg",
                    "text": "Beitrag des ESG-Controllings zu den SDGs"
                  },
            ] // navItems
    } // layout
  }

  const { navItems } = navDataThird.layout


  return (
    <Section id="convTest" className={container00Position} >
      <Container id="convContainerTest">

        <Flex id="convFlex1" variant="center" responsive>

          <Box id="convBox02" center paddingY={1} >
            <SubheadSmall>
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.heading}
            </SubheadSmall>
          </Box>

        
          <div id="convFlex2" className={conversionListWrapper}>
            <div className={conversionListTop}></div>
            <div className={conversionListCenter}>
              <FlexList id="convFle3" variant="spaceBetweenTop" responsive wrap gutter={0} gap={4} >
                  
                    {props.content.map((portfolio, index) => (
                      <li key={index} className={`${conversionListItem}`}>
                        <ConversionItem key={index} {...portfolio} />
                      </li>
                    ))}

              </FlexList>
            </div>
            <div className={conversionListBottom} ></div>
          </div>

          <Box id="convBox03" center width="eighty" >
              {props.value01 && <Text as="p" variant="lead" className={fontLeadSmaller}>{props.value01}</Text>}
              {props.value02 && <Text as="p" variant="lead" className={fontLeadSmaller}>{props.value02}</Text>}
          </Box>
          <Box id="convBox04" width="eighty" >

              <FlexList id="convFlex4" gap={3} variant="spaceBetweenTop" responsive className={listArrowBullets} >  {/* appears as row */}
                
                {navItems &&
                  navItems.map((details, i) => (
                    <li key={details.id} className={navBarThrid} >
                      
                      {details && 
                            <NavLink to={details.href} title={details.text} className={ `${navlink} ${navBarThridItem}` } >
                              {details.text}
                            </NavLink>
                      }

                    </li>
                ))}
             
              </FlexList>
          </Box>        
        
        </Flex>

      </Container>
    </Section>
  )
}