import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import {
  Box,
  ButtonList,
  Container,
  Flex,
  Section,
  Kicker,
  SuperHeading,
  SubheadSmall,
  Text,
} from "./ui"
// RH customisation
import { 
  hideOnSmallMedia, 
  parallaxCtlHeroRight, 
  parallaxCtlHeroLeft,
  heroPosition, 
  heroPositionBothSidesParallax,
  heroPositionFlex,
  smallMediaWidth,
  heroImgBoxMargin, 
  heroH2Adjust, 
  textLeft,
} from "../tailor.module.css"

import { Parallax } from 'react-scroll-parallax';


export default function Hero(props) {
  return (
    
      <Section>
        <Container variant="fullwidth" 
          className={heroPositionBothSidesParallax} 
          >


          <Flex id="heroFlex" gap={4} variant="column">
{/*            className={ heroPositionFlex } */}
            
            <Box id="top" width="twothirds" center
              className={ smallMediaWidth } 
              >

              <Parallax speed={1} > 
                <SuperHeading as="h1">
                  {props.kicker && <Kicker>{props.kicker}</Kicker>}
                  {props.h1}
                </SuperHeading>
              </Parallax>
          
              <Parallax speed={8} > 
                <SubheadSmall className={heroH2Adjust}>
                  {props.subhead}
                </SubheadSmall>
              </Parallax>
  {/*            <Text as="p">{props.text}</Text> */}
              <Parallax speed={13} > 
                  <ButtonList variant = "active" links={props.links} 
                    style={{ marginTop: "2px" }}
                    />
              </Parallax> 
            </Box>
          </Flex>

        </Container>
      </Section>
     
  )
}
