import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import {
	Flex,
  FlexList,
	Box,
  Heading,
  Text,
} from "./ui"
import {
  fitContent,
                                  imgEsgcCircleInModal, // currently not used
} from "../tailor.module.css"

/* this modal is intended to be used with `portal-esgc.jsx/InPortal` */


function Copyleft({ state }) {
  return (
    <Copyright>&#169; 2023 ESG-Controlling</Copyright>                    
  );
}


// ! width={700}  img width controlled via img wrapper div to better handle div screen aspect ratios

function ModalContentCircle({ state }) {
  return (
    <StyledContentCircle>

      <Box id="boxModal" >
          {
              Object.values(state)[0] ==='1' && 
                 <FigureContent>
                    <StaticImage src="../images/esgc_kreis_01_w2500_marked.png" alt="esg controlling circle 1" 
                      placeholder="blurred" className={imgEsgcCircleInModal} />
                    <TextCaption>
                      ESG-Controlling ist das unverzichtbare Bindeglied für die derzeit wichtigsten Schlüsselbereiche eines Unternehmens
                    </TextCaption>
                    <Copyleft />                    
                 </FigureContent>
          } 
          {
              Object.values(state)[0] ==='2' && 
                 <FigureContent>
                    <StaticImage src="../images/esgc_kreis_02_w2500_marked.png" alt="esg controlling circle 2" 
                      placeholder="blurred" className={imgEsgcCircleInModal} />
                    <TextCaption>
                      Alle wesentlichen Nachhaltigkeitsthemen beinhalten zahlreiche Indikatoren für die Defintion messbarer KPIs. 
                    </TextCaption>
                    <Copyleft />                    
                 </FigureContent>        } 
          {
              Object.values(state)[0] ==='3' && 
                 <FigureContent>
                    <StaticImage src="../images/esgc_kreis_04_w2500_marked.png" alt="esg controlling circle 4" 
                      placeholder="blurred" className={imgEsgcCircleInModal} />
                    <TextCaption>
                      Mit der ESG-Controlling Scorecard wird der Erfüllungsgrad der verschiedenen KPIs 
                      sofort sichtbar. In diesem Beispiel haben die CO<sub>2</sub>-Emissionen aus dem Stromeinkauf 
                      100% der Planziele erreicht. Handlungsbedarf besteht noch bei den CO<sub>2</sub>-Emissionen 
                      aus Geschäftsreisen (Erfüllungsgrad 25%).
                    </TextCaption>
                    <Copyleft />                    
                 </FigureContent>
          }     
      </Box>

      <CloseNote>
        &#10006; Klicken Sie auf eine beliebige Stelle, um das Fenster zu schließen / Click anywhere to close the modal &#10006;
      </CloseNote>
    </StyledContentCircle>
  );
}

/* image: https://github.com/gatsbyjs/gatsby/issues/14988 */
/* list : https://css-tricks.com/almanac/properties/w/width/ */
function ModalContentRect({ state }) {
  return (
    <ModalFlexList variant="column" id="listModal">

      <li className={fitContent}>
        {
          Object.values(state)[0] ==='4' && 
            <StaticImage src="../images/esgc_wertschoepfungskette_04_marked_w3300.png" alt="ESG-Controlling Wertschoepfungskette" 
              style={{ margin: '1rem' , maxHeight: 'calc(70vh - 3rem)', width: 'calc(80vw - 3rem)'}}
              imgStyle={{ objectFit: 'contain' }}
              placeholder="blurred" />
        } 
        {
          Object.values(state)[0] ==='6' &&
            <StaticImage src="../images/sihub_sdg_adaption_esgControlling_03_w2000.png" alt="ESG-Controlling und SDGs" 
              style={{ margin: '1rem' , maxHeight: 'calc(70vh - 3rem)', width: 'calc(80vw - 3rem)'}}
              imgStyle={{ objectFit: 'contain' }}
              placeholder="blurred" />
        } 
      </li>
      <li className={fitContent}>
        {
          Object.values(state)[0] ==='4' && 
            <TextCaption>
              Das ESG-Controlling bietet Indikatoren entlang der gesamten Wertschöpfungskette (Cradle to Cradle oder Cradle to Grave).
            </TextCaption>
        } 
        {
          Object.values(state)[0] ==='6' &&
            <TextCaption>
              Mit einem aussagekräftigen ESG-Controlling ist es möglich, die Nachhaltigkeitsleistung von 
              Unternehmen im Hinblick auf die Sustainable Development Goals zu quantifizieren.
            </TextCaption>
       } 
      </li>
      <li className={fitContent}>
        <CloseNote>
          &#10006; Klicken Sie auf eine beliebige Stelle, um das Fenster zu schließen / Click anywhere to close the modal &#10006;
        </CloseNote>
      </li>
    </ModalFlexList>
    
  );
}


/* https://stackoverflow.com/questions/67021885/ */
/* https://stackoverflow.com/questions/6268508 */
/* state necessary to be passed? */
const Modal2 = ({ isCircle, state }) => {

  // console.log("...................Modal2 state:",state)

  // this locks scroll of body (unlocked in each component that triggers the modal)
  document.body.style.overflowY = 'hidden';

  document.getElementById('portalcontainer').style.position = "fixed";
  document.getElementById('portalcontainer').style.zIndex = "199";
  document.getElementById('portalcontainer').style.top = "0";
  document.getElementById('portalcontainer').style.bottom = "0";
  document.getElementById('portalcontainer').style.left = "0";
  document.getElementById('portalcontainer').style.right = "0";

  document.getElementById('portalcontainer').animate([
    {opacity: '0'},
    {opacity: '1'}
  ], {
    duration: 500,
    easing: 'ease-in-out',
    fill: 'forwards'
  });

  function reset_animation() {
    var el = document.getElementById('portalcontainer');
    el.style.animation = 'none';
    el.style.animation = null; 
    el.style.position = 'block'; 
    el.style.zIndex = 'auto'; 
  }

  return (
    <div onClick={() => reset_animation()}>
      <StyledModal>
       {isCircle ? 
        <ModalContentCircle state={state} /> : <ModalContentRect state={state} />
       }
      </StyledModal>
    </div>
  );
};
export default Modal2;


const StyledModal = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.6);
`;
const StyledContentCircle = styled.div`
  width: 60%;                           /* fallback - will be overwritten in media settings */
  min-height: 50vh;                     /* fallback - will be overwritten in media settings */
  max-height: calc(100vh - 100px);      /* fallback - will be overwritten in media settings */
  padding: 30px;
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: auto;
  background-color: #FFF8E1; /* bg */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    /* following sets size of modal, not size of image (see .img-esgc-circle-in-modal) */
    /* wide screens */
    @media screen and (min-width: 1401px) and (min-height: 901px) {
      width: 60vw; 
      min-height: 90vh;
      max-height: calc(100vh - 100px);
    }
    @media screen and (min-width: 1401px) and (max-height: 900px) {
      width: 60vw;
      min-height: 98vh;
      max-height: 100vh;
    }
    /* smaller screens but landscape */
    @media screen and (max-width: 1400px) and (min-height: 901px) {
      width: 80vw;
      min-height: 98vh;
      max-height: 100vh;      
    }
    @media screen and (max-width: 1400px) and (max-height: 900px) {
      width: 80vw;
      min-height: 98vh;
      max-height: 100vh;
    }
    @media screen and (max-width: 745px) and (min-height: 901px) {
      width: 100%;
      min-height: 60vh;
      max-height: 100vh;
    }
    @media screen and (max-width: 745px) and (max-height: 900px) {
      width: 100%;
      min-height: 98vh;
      max-height: 100vh;
    }
`;
const ModalFlexList = styled(FlexList)`
/* max-height: calc(100vh - 100px); */
  padding: 30px 30px 8px 30px;
  background-color: #FFF8E1; /* bg */
`;
const StyledContentRect = styled.div`
  display: block;
  position:absolute;
/*  max-width: 80%; */
/*  max-height: 98vh; */
/*  max-height: calc(100vh - 100px); */
  padding: 30px;
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: auto;
  background-color: #FFF8E1; /* bg */

`;
const FigureContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  font-size: 1em;
  color: #452FA8;
  position: relative;
`;
const TextCaption = styled(Text)`
/*  width: 50%; */
/*  max-width: 90%; */
  padding-top: 1em;
`;
const CloseNote = styled.div`
  font-size: 0.8em;
  color: #452FA8;
`;
const Copyright = styled.div`
  font-size: 0.7em;
  color: #452FA8;
  position: absolute;
  top:0;
  align-self: flex-start;
`;


