import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "./feature-section"
import { 
  Container, 
  FlexList,
  Box,
  Kicker, 
  Heading, 
  SubheadSmaller,
  Space
} from "./ui"
import {
  hideOnSmallMedia,
  boxEsgcRectMargin,
  paddingBoxRightLeft,
  boxGradientGreen,
  featureListItem,
  imgRectSdgImg, 
  imgRectEsgBottom, 
  imgRectEsgAufwand,
} from "../tailor.module.css"


// 20221231 subst by reuseable jsx, renamed to FeatureSection

// keep padding 4 since this is used by media settings too
export default function FeatureList(props) {
  return (
    <Container id="aufwand" width="fullbleed" >

      <Box radius="small" shadow="large" textcolor="background"
        className={ `${boxGradientGreen} ${paddingBoxRightLeft} ${boxEsgcRectMargin}` }
        >
        <Box center paddingY={5} padding={4}>
          <Heading>
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
        </Box>

        <FlexList gap={4} variant="start" responsive>
          {props.content.map((details, i) => (
            <li key={details.id} className={featureListItem}>
              <FeatureSection {...details} />
            </li>
          ))}
        </FlexList>
        <Space size={4} />
        <Box center>
          {props.text && 
            <SubheadSmaller width="threequarter" >{props.text}</SubheadSmaller>}
            <div className={ `${imgRectEsgBottom} ${imgRectEsgAufwand}` }></div>
{/*            
          <StaticImage src="../images/tmpBg02.jpg" alt="ESG-Controlling zu geringen Kosten" placeholder="blurred" 
              width={1000} className={ `${imgRectEsgBottom} ${imgRectEsgAufwand}` } /> 
*/}              
        </Box>
      </Box>
    </Container>
  )
}

