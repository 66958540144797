import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css"

import { 
  pageWrapper,
  backgroundWrapper,
  regionParallax,
  parallaxRight,  
  parallaxLeft,
} from "../tailor.module.css"

import { ReactComponent as Leaf01 } from "../assets/esgc_bg_leaf-01b_DFE9CD_01.svg"
import { ReactComponent as Leaf02 } from "../assets/esgc_bg_leaf-02a_DFE9CD_01.svg"
import { ReactComponent as Leaf03 } from "../assets/esgc_bg_leaf-02b_DFE9CD_01.svg"

import { ReactComponent as NumberCloud03 } from "../assets/esgc_bg_numerCloud-03_DFE9CD_01.svg"
import { ReactComponent as BoxWhisker02 } from "../assets/esgc_bg_boxWhisker-02a_DFE9CD_01.svg"
import { ReactComponent as IMac01 } from "../assets/esgc_bg_accountingElements_iMac.svg"
import { ReactComponent as BarChart02 } from "../assets/esgc_bg_accountingElements_barChart-02.svg"
import { ReactComponent as NumberCloud02 } from "../assets/esgc_bg_numerCloud-02_DFE9CD_01.svg"
import { ReactComponent as LineChart02 } from "../assets/esgc_bg_accountingElements_lineChart-02.svg"

import { Parallax  } from "react-scroll-parallax"


const Layout = (props) => {
  return (
    <>
      <Head {...props} />
      <Header />
        <div className={pageWrapper}>
            {props.children}
        </div>
        <div className={backgroundWrapper}>

          <div className={regionParallax}>
            
            <div className={parallaxRight}>
                <Parallax translateY={[22,-20]} scale={[.7,.6]}>
                  <Leaf01 /></Parallax>                 
                <Parallax translateY={[10,-60]} scale={[.7,.6]} >
                  <Leaf02 /></Parallax>
                <Parallax translateY={[-10,-70]} scale={[.7,.6]}>
                  <Leaf01 /></Parallax>
                <Parallax translateY={[0,-60]} scale={[.7,.6]}>
                  <Leaf03 /></Parallax>
            </div>
            <div className={parallaxLeft}>
                <Parallax translateY={[0,-20]} translateX={[-3,-10]} scale={[0.6,0.5]} > 
                  <NumberCloud03 /></Parallax>            
                <Parallax translateY={[0,-40]} translateX={[5,-3]} scale={[.8,0.7]} > 
                  <BoxWhisker02 /></Parallax>
                <Parallax translateY={[100,-120]} translateX={[12,-2]} scale={[1,0.7]} >
                  <IMac01 /></Parallax>
                <Parallax translateY={[120,-70]} translateX={[15,1]} scale={[1,0.7]} >
                  <BarChart02 /></Parallax>
                <Parallax translateY={[80,-20]} translateX={[-4,-9]} scale={[.7,0.6]}>
                  <NumberCloud02 /></Parallax>
                <Parallax translateY={[50,-50]} translateX={[1,-5]} scale={[.9,0.7]}>
                    <LineChart02 /></Parallax>
            </div>


          </div>
        </div>
      <Footer />
    </>
  )
}
{/*
const Layout = (props) => {
  return (
    <>
      <Head {...props} />
      <Header />
            {props.children}
      <Footer />
    </>
  )
}
*/}
export default Layout
