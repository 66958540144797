import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "./feature-section"

import InPortal from "./portal-esgc"
import Modal2 from "./modal-content-for-portal"
import {isMobile} from 'react-device-detect';

import { 
  Container, 
  FlexList,
  Box,
  Kicker, 
  Heading, 
  SubheadSmaller,
  Space
} from "./ui"
import {
  hideOnSmallMedia,
  paddingBoxRightLeft,
  boxEsgcRectMargin,
  boxGradientGreen,
  featureListItem,
  imgRectSdgImg, 
  imgRectEsgBottom, 
  imgRectEsgImg,
  divClickable,
} from "../tailor.module.css"



// note: its also possible to serve 'alt' by props.image.alt or so

// 20221231 subst by reuseable jsx, renamed to FeatureSection

// keep padding 4 since this is used by media settings too
export default function FeatureList(props) {

  const [isOpen, setIsOpen] = React.useState(false);

  //console.log("....................06 isOpen:",isOpen)

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflowY = 'visible';
  }
  
  return (
    <Container id="sdg" width="fullbleed" >

      <Box radius="small" shadow="large" textcolor="background"
        className={ `${boxGradientGreen} ${paddingBoxRightLeft} ${boxEsgcRectMargin}` }
        >
        <Box center paddingY={5} padding={4}>
          <Heading>
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
        </Box>

        <FlexList gap={4} variant="start" responsive>
          {props.content.map((details, i) => (
            <li key={details.id} className={featureListItem}>
              <FeatureSection {...details} />
            </li>
          ))}
        </FlexList>
        <Space size={4} />
        <Box id="sdgBox3" center>
          {props.text && 
            <SubheadSmaller width="threequarter" >{props.text}</SubheadSmaller>}

            <Box id="sdgBox4" 
              className={`${divClickable}`}
              onClick={() => isOpen ? closeModal() : setIsOpen(true)} >
                {isOpen && !isMobile && 
                  <InPortal id="portalcontainer">
                    <Modal2 isCircle={false} state="6" />
                  </InPortal>
                }
              <StaticImage src="../images/sihub_sdg_adaption_esgControlling_vn02_w3000.png" alt="ESG-Controlling und SDGs" placeholder="blurred" 
                width={1000} className={imgRectEsgBottom} imgClassName={imgRectEsgImg} />
            </Box>
        </Box>
      </Box>
    </Container>
  )
}

