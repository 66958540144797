import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "./feature-section"

import InPortal from "./portal-esgc"
import Modal2 from "./modal-content-for-portal"
import {isMobile} from 'react-device-detect';

import { 
  Container, 
  FlexList,
  Box,
  Kicker, 
  Heading, 
  Subhead,
  Space,
} from "./ui"
import { 
  hideOnSmallMedia,
  paddingBoxRightLeft,
  boxEsgcRectMargin,
  boxGradientGreen,
  featureListItem,
  boxMargin, 
  imgRectEsgBottom, 
  imgRectEsgImg,
  divClickable,
 } from "../tailor.module.css"



// note: its also possible to serve 'alt' by props.image.alt or so


// keep padding 4 since this is used by media settings too  
export default function EinsatzList(props) {
  // console.log("* * * EinsatzList:",props.content)

  const [isOpen, setIsOpen] = React.useState(false);

  //console.log("....................04 isOpen:",isOpen)

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflowY = 'visible';
  }

  return (
<>
    <Container width="fullbleed" id="einsatz" >

      <Box id="einsatzBox01" radius="small" shadow="large" textcolor="background"
        className={ `${boxGradientGreen} ${paddingBoxRightLeft} ${boxEsgcRectMargin}` }
        >
        
        <Box id="einsatzBox02" center paddingY={5} padding={4}>
          <Heading>
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
        </Box>

        <FlexList id="einsatzFlexList01" gap={4} variant="start" responsive>
          {props.content.map((details, i) => (
            <li key={details.id} className={featureListItem}>
              <FeatureSection {...details} />
            </li>
          ))}
        </FlexList>

        <Space size={3} />

        <Box id="einsatzBox03" center>
          {props.text && 
            <Subhead width="threequarter" >{props.text}</Subhead>}
            <Box id="vorteileBox4" 
              className={`${divClickable}`}
              onClick={() => isOpen ? closeModal() : setIsOpen(true)} >
                {isOpen && !isMobile && 
                  <InPortal id="portalcontainer">
                    <Modal2 isCircle={false} state="4" />
                  </InPortal>
                }
              <StaticImage  src="../images/esgc_wertschoepfungskette_04_w3300.png" alt="ESG-Controlling Wertschoepfungskette" placeholder="blurred" 
                width={1000} 
                className={ `${imgRectEsgBottom}`} 
                imgClassName={imgRectEsgImg} />
          </Box>

        </Box>

      </Box>
    </Container>
</>    
  )
}

