import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "./feature-section"

import InPortal from "./portal-esgc"
import Modal2 from "./modal-content-for-portal"
import {isMobile} from 'react-device-detect';

import { 
  Container, 
  Flex,
  FlexList,
  Box,
  Kicker, 
  Heading,
  SubheadSmaller,
  Text,
} from "./ui"
import { 
  hideOnSmallMedia,
  paddingBoxRightLeft,
  imgCirclesEsg,
  boxGradientGreen,
  boxEsgcImg,
  boxEsgcImgBg,
  boxEsgcImgBgNew,
  boxHeader,
  imgEsgcCircleInBox,
  feartureListSecondRowShift,
  featureListItem,
  featureListBottomRow,
  divClickable,
} from "../tailor.module.css"


// Here, text is rendered as 2-column text, whre each column (i.e. a horizontal list) is served by 
// separate `content` sections of the json content model


// keep padding 4 since this is used by media settings too
export default function FeatureList(props) {

  const [isOpen, setIsOpen] = React.useState(false);
  
  //console.log("....................03 isOpen:",isOpen)

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflowY = 'visible';
  }
  
  return (
    <Container width="fullbleed" id="scorecard" >
      
      <Box id="scoreBox1" radius="small" shadow="large" textcolor="background"
        className={ `${boxGradientGreen} ${paddingBoxRightLeft}` }
        >
        <Flex id="scoreFlex1" variant="column" style={{ alignItems: "flex-start" , padding: "0" }} >

            <FlexList id="flex2" gap={4} responsive> 
<li>
              <Box id="scoreBox2" width="full" className={boxEsgcImgBgNew}>
                <Box id="scoreBox3" 
                  className={`${boxEsgcImg} ${divClickable}`}
                  onClick={() => isOpen ? closeModal() : setIsOpen(true)} >
                    {isOpen && !isMobile && 
                      <InPortal id="portalcontainer">
                        <Modal2 isCircle={true} state="3" />
                      </InPortal>
                    }
                    <StaticImage src="../images/esgc_kreis_04_w2500.png" alt="esg controlling circle 4" 
                      width={550} placeholder="blurred" 
                      className={imgEsgcCircleInBox} />
                </Box>
              </Box>
</li>
<li>
              <Box id="scoreBox4" center paddingY={5} padding={0} className={boxHeader}>
                <Heading>
                  {props.kicker && <Kicker>{props.kicker}</Kicker>}
                  {props.heading}
                </Heading>           
              </Box> 
</li>
            </FlexList>

            <FlexList id="flex3" gap={4} variant="start" responsive className={feartureListSecondRowShift}>  {/* appears as row */}
                {props.content.map((details, i) => (
                  <li key={details.id} className={featureListItem}>
                    <FeatureSection {...details} option={`body`} state={`* * * * * * * * *`} />
                  </li>
                ))}
            </FlexList>


{/*         style= {{ padding: "0 0 32px 0" }}  */}
            <Box id="scoreBox5"
              className={featureListBottomRow}
              >
                {props.text && 
                  <Text as="p" variant="lead" >{props.text}</Text>}        
            </Box>


        </Flex>


      </Box>
    </Container>
  )
}

