import * as React from "react"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import { LandingData } from '../content/content';

//console.log("LandingData:", LandingData )

const { homepage } = LandingData.data
//console.log("homepage:",homepage)


const IndexPage = () => {
  return (
      <>
      <Layout {...homepage}>

        {homepage.blocks.map((block) => {
          const { id, blocktype, ...componentProps } = block
          const Component = sections[blocktype] 
          return <Component key={id} {...componentProps} />
        })}
      </Layout>

      <div id="portalcontainer"></div>

      </>
  )  
}
export default IndexPage
