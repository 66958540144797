import * as React from "react"
import { 
  Container, 
  Section,
  Flex,
  Box,
  Kicker, 
  Subhead,
  Text,
  NavLink
} from "./ui"
import {
  featureListAdapt,
} from "../tailor.module.css"


// this reuseable is intendet to show the apragraphs of a 2-column text within a Box component
// within each column, several paragraphs can be defined and are rendered here

export default function FeatureSection( props ) {
  // console.log("FeatureSection:",props)
  // console.log("FeatureSection option:",props.option)
  return (
        <Flex id="FlexInner" gap={0} variant="column" style= {{ alignItems: "flex-start" }} >
          <Box id="BoxInner"  className={featureListAdapt} >
            <Subhead>
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.heading}
            </Subhead>
            {props.children.map((data, index) => (
              <Text key={index} as="p" variant={props.option} >{data.textContent}</Text>
            ))}

            {props.links?.map((link, index) => (
                <NavLink to={link.href} target="_blank">
                    <Text variant="small">&#8227;&nbsp;&nbsp;&nbsp;{link.text}</Text>
                </NavLink>
            ))}
          </Box>
        </Flex>
  )
}
