import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "./feature-section"
import InPortal from "./portal-esgc"
import Modal2 from "./modal-content-for-portal"
import {isMobile} from "react-device-detect"

import { 
  Container, 
  Flex,
  FlexList,
  Box,
  Kicker, 
  Heading,
  Text,
} from "./ui"
import { 
  paddingBoxRightLeft,
  boxEsgcCircleMargin,
  container01Position, // container repositioning only needed for 1st content section to opt. hero appearence
  boxGradientGreen,
  boxEsgcImg,
  boxEsgcImgBgNew,
  boxHeader,
  imgEsgcCircleInBox,
  feartureListSecondRowShift,
  featureListItem,
  featureListBottomRow,
  divClickable,
} from "../tailor.module.css"


// Here, text is rendered as 2-column text, whre each column (i.e. a horizontal list) is served by 
// separate `content` sections of the json content model


// keep padding 4 since this is used by media settings too

// R E F A C T O R ?? bottom section: <Box id="modalhere" ??
export default function FeatureList(props) {

  const [isOpen, setIsOpen] = React.useState(false);

  // console.log("....................01 isOpen:",isOpen)
  // console.log("....................01 isMobile:",isMobile)

  // prevent img download - works on all images on site
  // https://github.com/gatsbyjs/gatsby/issues/24030
  //
  // https://stackoverflow.com/questions/66602871
  // if (typeof window !== `undefined`) { // or typeof document !== 'undefined'
  if (typeof document !== `undefined`) { 
    document.addEventListener('contextmenu', (e) => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault()
        e.stopPropagation()
      }
    });
  }
    
  function closeModal() {
    setIsOpen(false);
    document.body.style.overflowY = 'visible';
  }
  
  return (
    <Container width="fullbleed" id="vorteile" 
      className={ `${container01Position}` }
      >
      
      <Box id="box1" radius="small" shadow="large" textcolor="background"
        className={ `${boxGradientGreen} ${paddingBoxRightLeft} ${boxEsgcCircleMargin}` }
        >
        <Flex id="flex1" variant="column" style={{ alignItems: "flex-start" , padding: "0" }} >

            <FlexList id="flex2" gap={4} responsive> 
              <li>
                <Box id="box2" width="full" className={boxEsgcImgBgNew}>
                  <Box id="box3" 
                    className={`${boxEsgcImg} ${divClickable}`} 
                    onClick={() => isOpen ? closeModal() : setIsOpen(true)}  >
                    {isOpen && !isMobile && 
                      <InPortal id="portalcontainer">
                        <Modal2 isCircle={true} state="1" />
                      </InPortal>
                    }   
                    <StaticImage src="../images/esgc_kreis_01_w2500.png" alt="esg controlling circle 1" 
                      width={550} placeholder="blurred" 
                        className={imgEsgcCircleInBox} />
                  </Box>
                </Box>
              </li>
              <li>
                <Box id="box4" center paddingY={5} padding={0} className={boxHeader}
                  >
                  <Heading>
                    {props.kicker && <Kicker>{props.kicker}</Kicker>}
                    {props.heading}
                  </Heading>           
                </Box> 
              </li>
            </FlexList>

            <FlexList id="flex3" gap={4} variant="start" responsive className={feartureListSecondRowShift}>  {/* appears as row */}
                {props.content.map((details, i) => (
                  <li key={details.id} className={featureListItem}>
                    <FeatureSection {...details} />
                  </li>
                ))}
            </FlexList>

            <Box id="modalhere"
              className={`${featureListBottomRow}`}>
                {props.text && 
                  <Text as="p" variant="lead" >{props.text}</Text>}

            </Box>

        </Flex>


      </Box>
    </Container>
  )
}

